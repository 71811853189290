import React, {useEffect} from 'react';
import * as styles from './Testimonials.module.scss';
import clsx from 'clsx';
import Title from '../Title/Title';

function TestimonialItem({data}) {
  return (
    <div className={clsx(styles.item)}>
      <div className={clsx(styles.itemAuthor)}>
        <div className={clsx(styles.itemAuthorPhoto)}>
          {data.photo.url && <img src={data.photo.url} alt={data.name.text}/>}
        </div>
        <div>
          <h3 className={clsx(styles.itemAuthorName)}>{data.name.text}</h3>
          <p className={clsx(styles.itemAuthorRole)}>{data.job_title.text}</p>
        </div>
      </div>
      <div className={clsx(styles.itemQuote)}>
        <p>”{data.testimonial_text.text}”</p>
      </div>
    </div>
  )
}

export default function Testimonials({data}) {
  const [titleDistance, setTitleDistance] = React.useState(0);

  useEffect(() => {
    const title = document.querySelector('h2');
    setTitleDistance(title.getBoundingClientRect().left);
  }, []);


  return (
    <section className={clsx(styles.testimonials, data.css_class)}>
      <div className={clsx('')}>
        <div className={clsx('section-wrapper',styles.content)}>
          <div className={styles.headline}>
            <Title><h2>Testimonials</h2></Title>
          </div>
        </div>
        <div className={styles.list} style={{paddingLeft: titleDistance + 'px'}}>
          {data.map((item, index) => (
            <TestimonialItem key={index} data={item}/>
          ))}
        </div>
      </div>
    </section>
  )
}
