import React from 'react';
import {graphql} from 'gatsby';
import clsx from 'clsx';
import Layout from '../components/layout/';
import SearchEngineOpt from '../components/SearchEngineOpt/SearchEngineOpt';
import Callout from '../components/Callout/Callout';
import Hero from '../components/Hero/Hero';
import ImageText from '../components/ImageText/ImageText';
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import Testimonials from "../components/Testimonials/Testimonials";

const IndexPage = ({data}) => {
  return (
    <Layout>
      <div className="page">
        <Hero className={clsx(data.page.data.header_css_class.text, 'hero-careers')}
              text={data.page.data.header_text.html} image={data.page.data.header_image}/>
        {data.page.data.sections.map((section, index) => (
          <ImageText key={index} data={section}/>
        ))}
        {/* <VideoPlayer
          className={'careers-video'}
          headline={data.page.data.video_headline.html}
          bgImage={data.page.data.video_poster_image.url}
          video={data.page.data.video_url.text}/> */}
        <Testimonials data={data.page.data.testimonials}/>
        <Callout
          text={data.page.data.callout_text.html}
          headline={data.page.data.callout_headline.html}
          subhead={data.page.data.callout_subhead.html}
          cta={{
            text: data.page.data.callout_cta_text.text,
            url: data.page.data.callout_cta_url
          }}
          image={data.page.data.callout_image}
          className={'careers-cta'}
        />
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head = ({data}) => (
  <SearchEngineOpt
    title={data.page.seo.title && data.page.seo.title.text}
    description={data.page.seo.description && data.page.seo.description.text}
    image={data.page.seo.image && data.page.seo.image.url}
    keywords={''}/>
)

export const IndexQuery = graphql`
query {
  page: prismicCareers {
        uid
        seo: data {
          title {
            text
          }
          description {
            text
          }
          image {
            url
          }
        }
        data {
      header_text {
        html
      }
      header_image {
        url
        alt
      }
      header_css_class {
        text
      }
      breadcrumb {
        link_text {
          text
        }
        link_url {
          target
          slug
          link_type
          url
        }
      }
      breadcrumb_css_class {
        text
      }
      sections {
        headline {
          html
        }
        paragraph {
          html
        }
        image {
          alt
          url
        }
        image_css_class {
          text
        }
        background_image {
          url
        }
        cta_text {
          text
        }
        cta_url {
          uid
          target
          slug
          link_type
          url
        }
        css_class {
          text
        }
      }
      video_headline {
        html
      }
      video_url {
        text
      }
      video_poster_image {
      	url
      }
      testimonials {
        name {
          text
        }
        job_title {
          text
        }
        photo {
          url
        }
        testimonial_text {
          text
        }
      }
      callout_headline {
        html
      }
      callout_subhead {
        html
      }
      callout_text {
        html
      }
      callout_cta_text {
        text
      }
      callout_cta_url {
        target
        slug
        link_type
        url
      }
      callout_image {
        alt
        url
      }
    }
  }
}
`
